<template>
    <div class="QA">
        <div class="QA-comp">
            <div class="QA-comp-center QA-m">
                <div class="spin-nested-loading">
                    <div class="spin-QA">
                        <div class="game-list">
                            <el-row style="margin-top: 20px">
                                <el-col :span="24">
                                    <el-input
                                        style="width: 240px"
                                        size="medium"
                                        v-model="QATitle"
                                        placeholder="请输入想要搜索的内容"
                                    >
                                        <i
                                            slot="suffix"
                                            class="
                                                el-input__icon
                                                el-icon-search
                                            "
                                        ></i>
                                    </el-input>
                                    <el-button
                                        @click="launchQA"
                                        style="margin-left: 20px; width: 68px"
                                        size="small"
                                        type="primary"
                                        >问答</el-button
                                    >
                                </el-col>
                            </el-row>
                            <div class="question-list">
                                <div
                                    v-for="(item, index) in dataList"
                                    :key="index"
                                    class="question-item"
                                >
                                    <div class="question-item-title">
                                        <div class="question-item__left">
                                            <div @click="
                                                    ChangeState(
                                                        item.answer_info,
                                                        index
                                                    )" v-html="item.question_content" class="question-item__header">
                                                
                                            </div>
                                            <div class="question-item__footer">
                                                <i
                                                    style="
                                                        color: rgb(
                                                            43,
                                                            103,
                                                            238
                                                        );
                                                    "
                                                    class="el-icon-user"
                                                ></i>
                                                <div class="author">
                                                    问答用户{{index+1}}
                                                </div>
                                                <div class="date">
                                                    {{ item.question_time }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="question-item__right">
                                            <div
                                                class="answer-num"
                                                @click="
                                                    ChangeState(
                                                        item.answer_info,
                                                        index
                                                    )
                                                "
                                            >
                                                {{
                                                    item.answer_info.length
                                                }}回答
                                            </div>
                                            <span>
                                                <div
                                                    @click="reply(item)"
                                                    class="go-answer"
                                                >
                                                    去回答
                                                </div>
                                            </span>
                                            <span>
                                                <div
                                                    @click="DeleteComment(item)"
                                                    class="go-answer"
                                                >
                                                    删除
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <transition name="fade">
                                        <div v-if="index == show">
                                            <div
                                                class="question-item-centent"
                                                v-for="(
                                                    items, indexs
                                                ) in item.answer_info"
                                                :key="indexs"
                                            >
                                                <div
                                                    class="question-item-title"
                                                >
                                                    <div
                                                        class="
                                                            question-item__left
                                                        "
                                                    >
                                                        <div
                                                            class="
                                                                question-item__header
                                                            "
                                                            v-html="items.answer_content"
                                                        >
                                                        </div>
                                                        <div
                                                            class="
                                                                question-item__footer
                                                            "
                                                        >
                                                            <i
                                                                style="
                                                                    color: rgb(
                                                                        43,
                                                                        103,
                                                                        238
                                                                    );
                                                                "
                                                                class="
                                                                    el-icon-user
                                                                "
                                                            ></i>
                                                            <div class="author">
                                                                管理员
                                                            </div>
                                                            <div class="date">
                                                                {{
                                                                    items.answer_time
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="
                                                            question-item__right
                                                        "
                                                    >
                                                        <span>
                                                            <div
                                                                @click="
                                                                    DeleteReply(
                                                                        items,
                                                                        index
                                                                    )
                                                                "
                                                                class="
                                                                    go-answer
                                                                "
                                                            >
                                                                删除
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </div>
                            <pagination
                                :total="Total"
                                :page.sync="Params.pageNum"
                                :limit.sync="Params.pageSize"
                                @pagination="getdata"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="发起问答" :visible.sync="State" width="50%">
            <editor
                :minHeight="200"
                v-model="
                    question_content
                "
            ></editor>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="sendOutQA">发起问题</el-button>
            </span>
        </el-dialog>
        <el-dialog title="发起回复" :visible.sync="replyState" width="50%">
            <editor
                :minHeight="200"
                v-model="
                    answer_content
                "
            ></editor>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="sendOutreply"
                    >发布回答</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { search_question, delete_question,ask_question,answer_question } from "../../api/qa.js";
import { getToken } from "@/utils/auth";
import editor from "../ManagementCenter/editor";
export default {
    components: {
        editor,
    },
    data() {
        return {
            activeNames: [],
            Params: {
                pageNum: 1,
                pageSize: 8,
            },
            dataList: [],
            Total: 0,
            State: false,
            question_content:null,
            show: -1,
            QATitle: "",
            textarea: "",
            replyState: false,
            answer_content:null,
            Deleteparam: {
                answer_id: null,
                question_id: null,
            },
            question_id:null,
            level_info: -1,
        };
    },
    created() {
		this.level_info = sessionStorage.getItem("level_info")
        this.getdata();
    },
    methods: {
        getdata() {
            this.show = -1;
            search_question(this.Params).then((res) => {
                this.dataList = res.data.list;
                this.Total = res.data.total;
            });
        },
        handleChange(val) {
            console.log(val);
        },
        ChangeState(item, index) {
            if (item.length > 0) {
                if(this.show == index){
                    this.show = -1
                    return
                }
                this.show = index;
            } else {
                this.$message({
                    message: "暂无回复信息",
                    type: "warning",
                });
            }
        },
        // 发起回复
        reply(item) {
            if (getToken()) {
                this.question_id = item.question_id
                this.replyState = true;
            } else {
                this.$message({
                    message: "请先登录用户",
                    type: "warning",
                });
                this.$router.push("/login");
            }
        },
        // 发起问答
        launchQA() {
            if (getToken()) {
                this.State = true;
                this.question_content = null
            } else {
                this.$message({
                    message: "请先登录用户",
                    type: "warning",
                });
                this.$router.push("/login");
            }
        },
        // 发送问答
        sendOutQA() {
            ask_question({question_content:this.question_content}).then(res=>{
                if(res.code == 200){
                    this.State = false;
                    this.getdata();
                    this.$message({
                        type: "success",
                        message: "发起问答成功!",
                    });
                }
            })
            
        },
        // 发布回答
        sendOutreply() {
            answer_question({
                question_id:this.question_id,
                answer_content:this.answer_content
            }).then(res=>{
                if(res.code == 200){
                    this.replyState = false;
                    this.getdata();
                    this.$message({
                        type: "success",
                        message: "发布回答成功!",
                    });
                }
            })
        },
        // 删除评论
        DeleteComment(item) {
            this.$confirm("此操作将永久删除该评论, 是否继续?", "提示", {
                confirmButtonText: "删除",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    delete_question({ question_id: item.question_id }).then(
                        (res) => {
                            if (res.code == 200) {
                                this.getdata();
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                });
                            }
                        }
                    );
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        // 删除回复
        DeleteReply(item,index) {
            var CopyParams = JSON.parse(JSON.stringify(this.Params))
            this.$confirm("此操作将永久删除该回复, 是否继续?", "提示", {
                confirmButtonText: "删除",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    delete_question({answer_id:item.answer_id}).then(res=>{
                        if(res.code == 200){
                            this.Params = CopyParams
                            this.getdata()
                            this.show = index;
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
    },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s;
}
.fade-enter,
.fade-leave-active {
    height: 0px;
}
.collapse-title {
    // flex: 1 0 90%; //位于左侧
    flex: 0 1 54%; // 位于中间
    order: 1;
}

.QA {
    height: auto;
    .QA-comp {
        position: relative;
        height: auto;
        // padding-top: 40px;
        width: 100%;
        min-width: 992px;
        background-color: rgb(245, 250, 252);
        .QA-comp-center {
            margin: 0 auto;
            .spin-nested-loading {
                position: relative;
                .spin-QA {
                    position: relative;
                    .game-list {
                        min-height: 726px;
                        .question-list {
                            margin: 14px 0 0;
                            // border-bottom: 1px solid #f2f2f2;
                            .question-item {
                                width: 100%;
                                border-top: 1px solid #ccc;
                                // border-bottom: 1px solid #ccc;
                                // padding: 10px 0 16px;
                                // display: -webkit-box;
                                // display: -ms-flexbox;
                                // display: flex;
                                // -webkit-box-orient: horizontal;
                                // -webkit-box-direction: normal;
                                // -ms-flex-flow: row nowrap;
                                // flex-flow: row nowrap;
                                // border-bottom: 1px solid #f2f2f2;
                                .question-item-centent {
                                    animation: height 5s;
                                    width: 100%;
                                    // background-color: #ccc;
                                    .question-item-title {
                                        width: 100%;
                                        padding: 10px 16px;
                                        display: -webkit-box;
                                        display: -ms-flexbox;
                                        display: flex;
                                        -webkit-box-orient: horizontal;
                                        -webkit-box-direction: normal;
                                        -ms-flex-flow: row nowrap;
                                        flex-flow: row nowrap;
                                        padding-left: 40px;
                                        .question-item__left {
                                            flex: 1;
                                            .question-item__header {
                                                font-size: 18px;
                                                color: #1a1a1a;
                                                margin: 0 0 9px;
                                                cursor: pointer;
												color: rgb(24,144,255);
												p{
													display: inline;
												}
                                            }
                                            .question-item__footer {
                                                display: flex;
                                                -webkit-box-orient: horizontal;
                                                -webkit-box-direction: normal;
                                                -ms-flex-flow: row nowrap;
                                                flex-flow: row nowrap;
                                                overflow: hidden;
                                                -webkit-box-align: center;
                                                -ms-flex-align: center;
                                                align-items: center;
                                                height: 24px;
                                                line-height: 24px;
                                                .author {
                                                    height: 24px;
                                                    line-height: 24px;
                                                    font-size: 14px;
                                                    color: #1b1724;
                                                    margin: 0 16px 0 4px;
                                                }
                                                .date {
                                                    height: 24px;
                                                    line-height: 24px;
                                                    font-size: 14px;
                                                    color: #979797;
                                                    margin: 0 30px 0 0;
                                                }
                                            }
                                        }
                                        .question-item__right {
                                            // width: 260px;
                                            margin: 0 0 0 20px;
                                            display: -webkit-box;
                                            display: -ms-flexbox;
                                            display: flex;
                                            -webkit-box-orient: horizontal;
                                            -webkit-box-direction: normal;
                                            -ms-flex-flow: row nowrap;
                                            flex-flow: row nowrap;
                                            -ms-flex-negative: 0;
                                            flex-shrink: 0;
                                            -webkit-box-pack: justify;
                                            -ms-flex-pack: justify;
                                            justify-content: right;
                                            .answer-num {
                                                padding: 0 10px;
                                                width: 68px;
                                                height: 26px;
                                                line-height: 26px;
                                                color: #585858;
                                                font-size: 12px;
                                                text-align: center;
                                                border-radius: 2px;
                                                background: #ccc;
                                                cursor: pointer;
                                            }
                                            span {
                                                padding: 0 10px;
                                                .go-answer {
                                                    display: block;
                                                    text-align: center;
                                                    width: 68px;
                                                    height: 26px;
                                                    line-height: 26px;
                                                    background: #2979ff;
                                                    border-radius: 2px;
                                                    font-size: 12px;
                                                    color: #fff;
                                                    cursor: pointer;
                                                }
                                            }
                                        }
                                    }
                                }
                                .question-item-title {
                                    width: 100%;
                                    padding: 10px 16px;
                                    display: -webkit-box;
                                    display: -ms-flexbox;
                                    display: flex;
                                    -webkit-box-orient: horizontal;
                                    -webkit-box-direction: normal;
                                    -ms-flex-flow: row nowrap;
                                    flex-flow: row nowrap;
                                    .question-item__left {
                                        flex: 1;
                                        .question-item__header {
                                            font-size: 18px;
                                            color: #1a1a1a;
                                            margin: 0 0 9px;
                                            cursor: pointer;
											color: rgb(24,144,255);
											p{
												display: inline;
											}
                                        }
                                        .question-item__footer {
                                            display: flex;
                                            -webkit-box-orient: horizontal;
                                            -webkit-box-direction: normal;
                                            -ms-flex-flow: row nowrap;
                                            flex-flow: row nowrap;
                                            overflow: hidden;
                                            -webkit-box-align: center;
                                            -ms-flex-align: center;
                                            align-items: center;
                                            height: 24px;
                                            line-height: 24px;
                                            .author {
                                                height: 24px;
                                                line-height: 24px;
                                                font-size: 14px;
                                                color: #1b1724;
                                                margin: 0 16px 0 4px;
                                            }
                                            .date {
                                                height: 24px;
                                                line-height: 24px;
                                                font-size: 14px;
                                                color: #979797;
                                                margin: 0 30px 0 0;
                                            }
                                        }
                                    }
                                    .question-item__right {
                                        // width: 260px;
                                        margin: 0 0 0 20px;
                                        display: -webkit-box;
                                        display: -ms-flexbox;
                                        display: flex;
                                        -webkit-box-orient: horizontal;
                                        -webkit-box-direction: normal;
                                        -ms-flex-flow: row nowrap;
                                        flex-flow: row nowrap;
                                        -ms-flex-negative: 0;
                                        flex-shrink: 0;
                                        -webkit-box-pack: justify;
                                        -ms-flex-pack: justify;
                                        justify-content: space-between;
                                        .answer-num {
                                            padding: 0 10px;
                                            width: 68px;
                                            height: 26px;
                                            line-height: 26px;
                                            color: #585858;
                                            font-size: 12px;
                                            text-align: center;
                                            border-radius: 2px;
                                            background: #ccc;
                                            cursor: pointer;
                                        }
                                        span {
                                            padding: 0 10px;
                                            .go-answer {
                                                display: block;
                                                text-align: center;
                                                width: 68px;
                                                height: 26px;
                                                line-height: 26px;
                                                background: #2979ff;
                                                border-radius: 2px;
                                                font-size: 12px;
                                                color: #fff;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1400px) {
    .QA-m {
        width: 1400px;
    }
}
@media (min-width: 992px) {
    .QA-m {
        width: 85.7%;
    }
}
</style>